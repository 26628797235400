/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-67o5vj css-shuj1p --style2 --full pb--60 pt--60" name={"9z1no8t9nte"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/122714bec4ab4ce7b75f5e9fa5e24a4a_s=3000x_.jpg);
    }
  
background-position: 52% 77%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Galerie"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"galerie"} style={{"backgroundColor":"rgba(112,156,159,1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/5ab3cdaa9e944b48a551d52de86c82ed_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/5ab3cdaa9e944b48a551d52de86c82ed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/5ab3cdaa9e944b48a551d52de86c82ed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/5ab3cdaa9e944b48a551d52de86c82ed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/5ab3cdaa9e944b48a551d52de86c82ed_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/940596e3ad0f4bf0a7fe25554aa8e8a7_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":672}} srcSet={"https://cdn.swbpg.com/t/18947/940596e3ad0f4bf0a7fe25554aa8e8a7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/940596e3ad0f4bf0a7fe25554aa8e8a7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/940596e3ad0f4bf0a7fe25554aa8e8a7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/940596e3ad0f4bf0a7fe25554aa8e8a7_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/db4338d412e9469d93b5d2a0eed3cf07_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":782}} srcSet={"https://cdn.swbpg.com/t/18947/db4338d412e9469d93b5d2a0eed3cf07_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/db4338d412e9469d93b5d2a0eed3cf07_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/db4338d412e9469d93b5d2a0eed3cf07_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/db4338d412e9469d93b5d2a0eed3cf07_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":732}} srcSet={"https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/54e5f7293068409b86c3015a4044523e_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=2000x_.jpeg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/3cd7bc4564df4ba0890d4cdb7f6ad280_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/3cd7bc4564df4ba0890d4cdb7f6ad280_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/3cd7bc4564df4ba0890d4cdb7f6ad280_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/3cd7bc4564df4ba0890d4cdb7f6ad280_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/3cd7bc4564df4ba0890d4cdb7f6ad280_s=1400x_.png 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/79cf0cb9b3e24bbe8e520381cf7aed5b_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":678}} srcSet={"https://cdn.swbpg.com/t/18947/79cf0cb9b3e24bbe8e520381cf7aed5b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/79cf0cb9b3e24bbe8e520381cf7aed5b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/79cf0cb9b3e24bbe8e520381cf7aed5b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/79cf0cb9b3e24bbe8e520381cf7aed5b_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/5b379727e91c431fbf8e28df2d0afc72_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":714}} srcSet={"https://cdn.swbpg.com/t/18947/5b379727e91c431fbf8e28df2d0afc72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/5b379727e91c431fbf8e28df2d0afc72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/5b379727e91c431fbf8e28df2d0afc72_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/5b379727e91c431fbf8e28df2d0afc72_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":744}} srcSet={"https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":750}} srcSet={"https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/08dbd5c9c8424d69b6e4607acbc7b8cb_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--justify pl--30" alt={""} src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":144}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-MAIL</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">info@oliverscoffeecup.cz</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">TELEFON</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">+420 234 101 138</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">SLEDUJTE NÁS</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"https://www.facebook.com/oliverscoffeecup.cz\" target=\"_blank\">Facebook<br></a><a href=\"https://www.instagram.com/oliverscoffeecup/\" target=\"_blank\">Instagram</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}